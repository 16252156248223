<script setup>
import {computed, defineEmits, onMounted, onUnmounted, ref, watch, nextTick} from 'vue'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
//import 'datatables.net-rowreorder';
//import 'datatables.net-scroller';
//import 'datatables.net-searchbuilder';
//import 'datatables.net-fixedcolumns';
//import 'datatables.net-searchpanes';
//import 'datatables.net-select';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
DataTable.use(DataTablesCore)

import { useQueryStore } from '@/stores/query'
let queryStore = useQueryStore()
import { Tooltip } from 'bootstrap'

import Button from '@/components/Blocks/Buttons/Default.vue'
import Dropdown from '@/components/Blocks/Buttons/Dropdown.vue'

const props = defineProps({
  data: {
    type: Array,
    default: [],
  },
  columns: {
    type: Array,
    default: [],
  }
});

// IMPORT WIDGETS
import Country from '@/components/Query/Widget/Country.vue'
import ItemDesc from '@/components/Query/Widget/ItemWithDesc.vue'
import Tags from '@/components/Query/Widget/Tags.vue'
import ProviderDesc from '@/components/Query/Widget/ProviderDesc.vue'
import TagsBasic from '@/components/Query/Widget/TagsBasic.vue'
import DateI from '@/components/Query/Widget/Date.vue'
import Indicators from '@/components/Query/Widget/Indicators.vue'
import Provider from '@/components/Query/Widget/Provider.vue'
import Anonymization from '@/components/Query/Widget/Anonymization.vue'
import Since from '@/components/Query/Widget/Since.vue'
import Reputation from '@/components/Query/Widget/Reputation.vue'
import IpWidget from '@/components/Query/Widget/Ip.vue'
import AsNumber from '@/components/Query/Widget/AsNumber.vue'
import AsOrg from '@/components/Query/Widget/AsNumber.vue'
import RiskScore from '@/components/Query/Widget/Riskscore.vue'
import HashType from '@/components/Query/Widget/HashType.vue'


const emit = defineEmits(['rowclick'])

const options = {
    pagingType: 'simple_numbers',
    paging: false,
    scrollCollapse: true,
    scrollY: true,
    scrollX: true,
    drawCallback: function(settings) {
      document.querySelector(".dt-scroll-body thead").style.display = "none"
    },
    layout: {
        topStart: null,
        topEnd: null,
        bottomStart: null,
        bottomEnd: null
    },
    /*
    fixedColumns: {
        start: 3
    },
    */
    //pageLength: 50,
}


const search_filter = ref("")
const page_info = ref({})
const table = ref()
const rtable = ref()
let dt; 

onMounted(async () => {
  dt = table.value.dt;

  dt.on('click', 'tbody tr td', function() {
    let header = dt.columns(this).header().textContent;
    if (header != undefined) {
      emit("rowclick", { obj: dt.row(this).data(), cell_name: dt.column(this).header().textContent })
    }
  });

  await nextTick()
  page_info.value = dt.page.info()
  //dt.columns.adjust()
})

watch(search_filter, (newval) => {
  dt.search(newval).draw()
  page_info.value = dt.page.info()
})

function download(csvString, filename = 'data.csv', type = "text/csv") {
  const blob = new Blob([csvString], { type: type + ';charset=utf-8;' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.display = 'none';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(url); // Clean up
}

function export_jsonl() {
  //let data = dt.buttons.exportData()
  //filter later

  let file = ""
  
  for(let i=0; i<props.data.length; i++) {
    file += JSON.stringify(props.data[i]) + "\n"
  }
  // Example usage:
  //const csv = `name,age\nAlice,30\nBob,25`;
  download(file, "data.jsonl", "application/json");
}

function export_json() {
  //let data = dt.buttons.exportData()
  //filter later

  let file = JSON.stringify(props.data)
  // Example usage:
  //const csv = `name,age\nAlice,30\nBob,25`;
  download(file, "data.json", "application/json");
}

function export_csv() {
  let data = dt.buttons.exportData()

  let csv = ""
  csv += data.header.join(",") + "\n"
  
  for(let i=0; i<data.body.length; i++) {
    csv += data.body[i].join(",") + "\n"
  }
  // Example usage:
  //const csv = `name,age\nAlice,30\nBob,25`;
  download(csv);
}
</script>

<template>
  <div>
    <div class="d-flex vertically-centered toolbox mb-2 flex">
      <input class="dt-input" v-model="search_filter" placeholder="filter results" height="100%"/>
      <div class="ms-3">
        showing {{ page_info.recordsDisplay }} <span v-if="search_filter != ''"> of {{ page_info.recordsTotal}}</span> results
      </div>
      <div class="ms-auto">
        <Dropdown>
          <template #button>export</template>
          <template #list>
            <li class="dropdown-item" @click="export_csv">csv</li>
            <li class="dropdown-item" @click="export_jsonl">jsonl</li>
            <li class="dropdown-item" @click="export_json">json</li>
          </template>
           
        </Dropdown>
      </div>
    </div>
    <DataTable
      v-if="props.columns != []"
      ref="table"
      :options="options"
      :columns="props.columns"
      :data="props.data"
      class="querytable">

      <template #column-ips="props">
        <IpWidget :data="props.cellData"/>
      </template>

      <template #column-as_number="props">
        <AsNumber :data="props.cellData"/>
      </template>

      <template #column-location="props">
        <Country :data="props.cellData"/>
      </template>

      <template #column-anonymization="props">
        <Anonymization :data="props.cellData"/>
      </template>

      <template #column-osint_mentions="props">
        <Indicators :data="props.cellData" />
      </template>

      <template #column-indicators="props">
        <Indicators :data="props.cellData" />
      </template>

      <template #column-behavior="props">
        <Tags :data="props.cellData" :maxlen="3" />
      </template>

      <template #column-osint="props">
        <Tags :data="props.cellData" :maxlen="3" />
      </template>

      <template #column-companybusiness="props">
        <Tags :data="props.cellData" :maxlen="3" />
      </template>

      <template #column-operator="props">
        <ItemDesc :data="props.cellData" />
      </template>

      <template #column-fingerprint_type="props">
        <HashType :data="props.cellData" />
      </template>

      <template #column-provider="props">
        <Provider :data="props.cellData"/>
      </template>

      <template #column-network_provider_services="props">
        <TagsBasic :data="props.cellData" :maxlen="3" />
      </template>

      <template #column-hostnames="props">
        <TagsBasic :data="props.cellData" :maxlen="1" :nostyle="true" />
      </template>

      <template #column-open_ports="props">
        <TagsBasic :data="props.cellData" :maxlen="3" />
      </template>

      <template #column-asn="props">
        <TagsBasic :data="props.cellData" :maxlen="1" :nostyle="true"  />
      </template>

      <template #column-ip_indicators="props">
        <Indicators :data="props.cellData" />
      </template>

      <template #column-update_date="props">
        <Since :data="props.cellData" />
      </template>

      <template #column-publish_date="props">
        <Since :data="props.cellData" />
      </template>

      <template #column-expiration_date="props">
        <DateI :data="props.cellData" />
      </template>

      <template #column-registration_date="props">
        <DateI :data="props.cellData" />
      </template>

      <template #column-provider_description="props">
        <ProviderDesc :data="props.cellData" />
      </template>

      <template #column-operator_description="props">
        <ProviderDesc :data="props.cellData" />
      </template>

      <template #column-risk="props">
        <RiskScore :data="props.cellData" />
      </template>

      <template #column-domains_on_ip="props">
        <div>
          <span v-if="props.cellData >= 1000">1000+</span>
          <span v-else>{{props.cellData}}</span>
        </div>
      </template>

    </DataTable>
  </div>
</template>
<style scoped>
.dt-input {
  display: block;
  background-color: #131820;
  border: none;
  padding: .5rem 1rem;
  color: white;
  border-radius: 10px;
  outline: none;
}
</style>

<style>

.datatable { 
  min-width: 100%;
  height: calc(100% - 40px - .5rem );

  background-color: #1E252F;
  border-radius: 10px;
  overflow: hidden;
}

.querytable {
  table-layout: fixed;
  width: unset !important;
  min-width: 100% !important;
}

.datatable tr:hover {
  background-color: #1f252f;
}

.datatable tr td:hover {
  background-color: #262e3a;
}

.datatable td {
  word-break: break-all;
  nowrap: nowrap;
}

.datatable td {
  /*verflow: hidden;
  text-overflow: ellipsis;
  max-width: 30rem;
  */
  white-space: nowrap;
  background-color: #1E252F;
}

.datatable td.selected {
  background-color: #364c6a;
}

.datatable th:not(:first-child) {
  border-left: 1px solid #3E4959;
}

.datatable th:not(:last-child) {
  border-right: 1px solid #3E4959;
}

.datatable td:not(:first-child) {
  border-left: 1px solid #29323E;
}

.datatable td:not(:last-child) {
  border-right: 1px solid #29323E;
}

.px200 {
  overflow: hidden;
  max-width: 300px;
  word-wrap: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dataTables_filter {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: .7rem;
}


.querytable th {
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #2b333e;
  font-weight: 500;
  white-space: nowrap;
  /*
  border-bottom: 2px solid #191f27;
  border-top: 2px solid #191f27;
  */
  color: white;

}

.querytable td,th {
  /*
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  */
  padding: .5rem 1rem;
}

.querytable td {
  color: #cdd5e0 !important;
  font-size: .9rem;
}

.dt-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.querytable tbody tr {
  border-bottom: 1px solid #29323E;
}

.dt-layout-table {
  flex: 1;
  min-height: 0; 
}

.dt-layout-row:has(.dt-start) {
  background-color: #2b333e;
  color: white;
  display: flex;
  padding: 1rem 2rem;
}

div.dataTables_scrollBody .h_cell {
  padding: 0 4px !important;
}

.dt-layout-row .dt-end {
  margin-left: auto;
}

.paging_simple_numbers button {
  background-color: #29323E;
  border-radius: 5px;
  border:none;
  margin-right: 5px;
  padding: 0 10px;
  color: white;
}

.paging_simple_numbers button:hover:not(.current) {
  background-color: #2B3440;
}
.dt-paging-button.current {
  background-color: #37414F;
}

.dt-scroll-body table {
  /*overflow: hidden; */
}

.dt-scroll-body thead {
  /*
  display: none;
  */
  visibility: collapse;
}

.dt-scroll-body thead tr th {
  /*
  display: none;
  */
  height: 0;
  padding-bottom: 0;
  padding-top: 0;
  overflow: hidden;
}

.dt-scroll-body thead tr th * {
  /*
  display: none;
  */
  height: 0; 
  overflow: hidden;
}


.dt-layout-cell {
  height: 100%;
  align-self: stretch;
}

.dt-scroll {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dt-scroll-body {
  max-height: calc(100% - 42px);
  flex: 1;
}

.underlined {
  text-decoration: underline;
}

.dropdown-item {
  cursor: pointer;
}
</style>
