<script setup>
import {onMounted, ref, defineProps, computed, nextTick, watch} from 'vue'
import moment from 'moment'

import { useUserStore } from '@/stores/user'
let userStore = useUserStore()

import { useQueryStore } from '@/stores/query'
let queryStore = useQueryStore()

import { useRouter } from 'vue-router'
const router = useRouter()

import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

import QueryRunner from '@/views/Authenticated/Query/Runner.vue'

onMounted(  async () => {
  userStore.getQueries()
})

function clear_query() {
  queryStore.result = null
  queryStore.error = ""
  return true
}

async function exec_query(input, query_type) {
  //await queryStore.query(input)
  let value = input.trim().split(",")
   mainStore.show_slideover(QueryRunner, { input: value, type: query_type }, { width: 10 }) 
}

function pretty_print(query_type) {
  if (query_type == "ip") return "Ip"
  else if (query_type == "ip_table") return "Ip Bulk"
  else if (query_type == "subdomains") return "Subdomains"
  else if (query_type == "emails") return "Emails"
  else if (query_type == "similar") return "Similar domains"
  else if (query_type == "reverse_dns") return "Reverse DNS"
  else if (query_type == "fingerprint_ips") return "Fingerprint to Ips"
  else if (query_type == "ip_fingerprints") return "Ip fingerprints"
  else if (query_type == "signals_toptalkers") return "Signals Toptalkers"
  else return query_type
}
</script>


<template>
  <div class="b">
    <div>
      <table class="table">
        <thead>
          <tr>
            <th style="width: 15%">Time</th>
            <th style="width: 15%">Type</th>
            <th style="width: 60%">Observables</th>
            <th style="width: 10%">Credits Used</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="query in userStore.queries" @click="exec_query(query.query, query.type)"  class="query_history_cell" :key="query">
            <td >{{ moment(query.timestamp).fromNow() }}</td>
            <td >{{ pretty_print(query.type) }}</td>
            <td>{{ query.query}}</td>
            <td >{{ query.credits_used}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>

.table {
  width: 100%;
  table-layout: fixed;
}

.table th {
  color: white;
  font-weight: normal
}

.table tr {
  border-bottom: 1px solid #29323E;
}

.table tbody tr:last-child {
  border-bottom: 1px solid transparent;
}

.table td {
  color: #6D7785;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.query_history_cell:hover {
  background-color: #171c24;
  color: #cd317f;
}
.query_history_cell {
  cursor: pointer;
}

.clickable-item {
  cursor: pointer;
}

.clickable-item:hover {
  background-color: #171c24;
  border-radius: 5px;
}


</style>
