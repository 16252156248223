<script setup>
import {defineProps, onMounted, ref, computed} from 'vue'

const props = defineProps(["data"])

let providers = computed( () => {
  let prov = {}

  if (props.data != null && props.data != undefined) {
    for(let i=0; i<props.data.length;i++) {
      if (props.data[i].network_provider.trim() != "")
      prov[props.data[i].network_provider.trim()] = true
    }
  }

  return Object.keys(prov).join(",")
})

</script>

<template>
  <div class="d-flex vertically-centered" v-if="props.data != null">
    <span>{{ providers }}</span>
  </div>
</template>

<style scoped>

</style>
