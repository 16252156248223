import { defineStore } from 'pinia'
import { useUserStore} from './user'
const events = require('events')


//import General from '@/api/General.js'; 

export const useMainStore = defineStore('main', {
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      cookieConsent: false,
     
      slideover_history: [
        /*{
          name: ""
          component: {},
          props: {}
        }*/
      ], 
      slideover_index: -1, 
      slideover_show: false,
      //slideover_component: null,
      //slideover_props: null,
      slideover_options: {},

      modal_show: false,
      modal_component: null,
      modal_props: null,
      modal_fullscreen: false,

      emitter: new events.EventEmitter()
    }
  },

  getters: {

  },

  actions: {
    async init() {
        let userStore = useUserStore()
        await userStore.init()
        /*
        if(validTo && Date.parse(validTo) > Date.now()) {
          // user cookie remains active
          // -- The user is already logged in --
          await userStore.init()
        } 
        */
        //this.countries = await General.countries();        
    },

    generateString(length) {
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = ' ';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    },

    show_slideover(comp, props, options, name) {
      if (name == undefined) {
        name = this.generateString(10)
      }
      this.slideover_history.push({
        name: name, 
        component: comp,
        properties: props
      })
      this.slideover_options = options
      this.slideover_index += 1
      this.slideover_show = true
      // we cannot open the same slideover ontop of itself,
      // the reative comps will break any current selected item
      // there is already an open slideover
      /*
      if (this.slideover_component != null) {
        alert("ITS OPEN")
        this.slideover_component = null
        this.slideover_props = null
        this.slideover_options = null
      }

      setTimeout(() => {
        this.slideover_component = comp
        this.slideover_props = props
        this.slideover_options = options
      }, 1000);
      this.slideover_show = true
      */
    },

    close_slideover() {
      // reset the slideover
      this.slideover_show = false
      this.slideover_history = []
      this.slideover_index = -1
      this.slideover_options = { width: 50 }
    
      // Query has likely been made, refresh them
      let userStore = useUserStore()
      userStore.getQueries();
    },

    show_modal(comp, props, fullscreen = false) {
      this.modal_props = props
      this.modal_component = comp
      this.modal_show = true,
      this.modal_fullscreen = fullscreen
    },

    close_modal() {
      this.modal_show = false
    },

    event_emit(key, data) {
      this.emitter.emit(key, data);
    },

    event_on(key, callback) {
      this.emitter.on(key, callback)
    },

    event_on_once(key, callback) {
      this.emitter.once(key, callback)
    }
  }
})
