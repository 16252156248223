import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class User {
  getJobs() {
    return axios.get(API_URL + '/job');
  }

  async getQueries() {
    const { data } = await axios.get(API_URL + '/account/queries')
    return data; 
  }

  async getDetails() {
    const { data } = await axios.get(API_URL + '/account/details')
    return data; 
  }

  async save_query(query, type) {
    try {
      const { data } = await axios.post(API_URL + '/query/save', { query: query, type: type });
      return data
    } catch( errors ) {
      return { status: "failed", error: errors.response }
    }
  }

  async contact(email, name, topic, message) {
    try {
      const { data } = await axios.post(API_URL + '/contact', { email: email, name: name, topic: topic, message: message});
      return data
    } catch( errors ) {
      return { status: "failed", error: errors.response }
    }
  }

  async getServices() {
    const { data } = await axios.get(API_URL + '/service')
    return data; 
  }

  async getDomains() {
    const { data } = await axios.get(API_URL + '/user/domains');
    return data
  }

  async getVerifiedDomains() {
    const { data } = await axios.get(API_URL + '/user/domains/verified');
    return data;
  }

  async getReports() {
    const { data } = await axios.get(API_URL + '/reports')
    return data; 
  }

  async getReport(id) {
    const { data } = await axios.get(API_URL + '/plan/report/result', { params: {report: id}})
    return data; 
  }

  async getStatus(id) {
    const { data } = await axios.get(API_URL + '/plan/reports/status', { params: {id: id}} )
    return data; 
  }

  async verifyDomain(domain) {
    const { data } = await axios.post(API_URL + '/user/domains/verify', { domain: domain} )
    return data; 
  }

  async removeDomain(domain) {
    const { data } = await axios.post(API_URL + '/user/domains/remove', { domain: domain} )
    return data; 
  }

  async list_api_keys() {
    const { data } = await axios.get(API_URL + '/account/list-keys')
    return data; 
  }

  async create_api_key(name, description) {
    try {
    const { data } = await axios.post(API_URL + '/account/create-key', { name: name, description: description} )
    return data; 
    } catch(exception) {
      return {status: "failed"}
    }
  }

  async delete_api_key(key_id) {
    const { data } = await axios.post(API_URL + '/account/delete-key', { id: key_id} )
    return data; 
  }

  listen_for_updates() {
    const ws = new WebSocket("ws://localhost:3010/api/plan/reports/status")
    
    return ws; 
  }

  async addDomain(domain) {
    const { data } = await axios.post(API_URL + '/user/domains/add', {
      domain: domain
    });
    return data;
  }

  createJob(serviceId) {
    return axios.post(API_URL + "/job/create", 
                    { 
                        service: serviceId,
                    }, 
                );
  }
}

export default new User();
