<script setup>
import { useUserStore } from '@/stores/user'
import { useRouter, useRoute} from 'vue-router'

import { useQueryStore } from '@/stores/query'
let queryStore = useQueryStore()

import ContactModal from '@/components/modals/Contact.vue'

import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()

async function logout() {
  await userStore.logout();
  return router.push({ path:"/" })
}

function clear_query() {
  queryStore.result = null
  queryStore.error = ""
  return true
}

//mainStore.show_modal(qf, { fileName: file.name, domains: res.domains, ips: res.ips})
function contact() {
  mainStore.show_modal(ContactModal, { sender: userStore.email, name: userStore.name }) 
}
</script>

<template>
    <div class="d-flex sidebar-wrapper">
    <nav id="sidebar">
      <ul class="navbar-nav mb-auto" ondragstart="return false;">
        <!--
        <li class="brand">
          <router-link class="sidebar-nav brand-img" to="/">
            <img src="~@/assets/webscout.svg" alt="" width="130">
          </router-link>
        </li>
        -->
        <li class="mb-2 mt-3">
          <router-link class="sidebar-nav" to="/">
            <img src="~@/assets/ws_logo_idea.svg" class="logo" height="30"/>
            <img src="~@/assets/webscout_white.svg" class="logo-text" height="20"/>
          </router-link>
        </li>

        <li>
          <router-link class="sidebar-nav" :class="{ 'router-link-exact-active': route.path == '/' }"  to="/" @click.prevent="clear_query">
            <font-awesome-icon  class="icon" :icon="['fas', 'search']" />
            <span class="text">enrichment engine</span>
          </router-link>
        </li>
        <li>
          <router-link class="sidebar-nav"  to="/signals" @click.prevent="clear_query">
            <font-awesome-icon  class="icon" :icon="['fas', 'rss']" />
            <span class="text">signals</span>
          </router-link>
        </li>
        <li>
          <router-link class="sidebar-nav"  to="/tools" @click.prevent="clear_query">
            <font-awesome-icon  class="icon" :icon="['fas', 'toolbox']" />
            <span class="text">enrichment tools</span>
          </router-link>
        </li>
        <!--
        <li>
          <router-link class="sidebar-nav" :class="{ 'router-link-exact-active': route.path == '/' }" to="/">
            <font-awesome-icon  class="icon" :icon="['fas', 'chart-line']" />
            <span class="text">dashboard</span>
          </router-link>
        </li>
        <li>
          <router-link class="sidebar-nav nav-link" to="/streams">
            <font-awesome-icon class="me-2" :icon="['fas', 'water']" />
            Streams
          </router-link>
        </li>
        <li>
          <router-link class="sidebar-nav nav-link" to="/feeds">
            <font-awesome-icon class="icon" :icon="['fas', 'download']" />
            <span class="text">datasets</span>
          </router-link>
        </li>
        -->
        <li>
          <router-link class="sidebar-nav nav-link" to="/docs/api">
            <font-awesome-icon  class="icon" :icon="['fas', 'code']" />
            <span class="text">api documentation</span>
          </router-link>
        </li>
      </ul> 
      <ul class="navbar-nav">
        <!--
        <li v-if="userStore.accountType < 5">
          <router-link class="sidebar-nav nav-link" to="/plans">
            <font-awesome-icon class="icon" :icon="['fas', 'chart-simple']" />
            <span class="text">plans</span>
          </router-link>
        </li>
        -->
        <li>
          <router-link class="sidebar-nav nav-link" to="/contact">
            <font-awesome-icon class="icon" :icon="['fas', 'message']" />
            <span class="text">contact & feedback</span>
          </router-link>
        </li>
        <li>
          <router-link class="sidebar-nav nav-link" to="/account">
            <font-awesome-icon class="icon" :icon="['fas', 'user-astronaut']" />
            <span class="text">account</span>
          </router-link>
        </li>
        <li @click="logout()" class="sidebar-nav" style="color: #ca5050; font-weight: bold;">
            <font-awesome-icon  class="icon" :icon="['fas', 'lock']" />
            <span class="text">logout</span>
        </li>
      </ul> 
    </nav>
    </div>
</template>
<style scoped>
.sidebar-wrapper {
  width: 85px;
  position: relative;
}

#sidebar {
  z-index: 10;
  padding: 0; 
  background: #151a22;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 85px;
  left: 0; 
  height: 100%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.brand {
  background-color: #151a22;
  min-height: 60px;
  /*
  border-right: 1px solid #191f27;
  border-bottom: 1px solid #191f27;
  */
}

#sidebar .sidebar-nav {
  padding: 1rem 2rem; 
  height: 4rem; 
  color: #7A8696;
  display: flex; 
  align-items: center;
}

#sidebar .sidebar-nav:not(.brand-img) {
  /*
  border-bottom: 1px solid #191f27;
  border-right: 1px solid #191f27;
  */
}

.sidebar-nav span {
  visibility: hidden;
  opacity: 0;
  text-wrap: nowrap;
  transition: visibility 0s, opacity 0.5s linear;
}

#sidebar .logo {
  margin-left: -7px;
}

.sidebar-nav .logo-text {
  visibility: hidden;
  opacity: 0;
  text-wrap: nowrap;
  transition: visibility 0s, opacity 0.5s linear;
}



#sidebar:hover {
   width: 300px; 
}

#sidebar:hover .sidebar-nav .icon {
  margin-right: 1rem;
}

#sidebar:hover .sidebar-nav .logo {
  margin-right: 1rem;
}


#sidebar:hover .sidebar-nav span {
  opacity: 1;
  visibility: visible;
}

#sidebar:hover .sidebar-nav .logo-text {
  opacity: 1;
  visibility: visible;
}


#sidebar .sidebar-nav:hover {
  color: white; 
  cursor: pointer;
  background-color: #161c24;
}

.router-link-active {
  /*font-weight: 500 !important;*/
  color: white !important;
}

.router-link-exact-active {
  /*font-weight: 500 !important;*/
  color: white !important;
}
</style>
