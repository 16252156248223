<script setup>
import {defineProps, computed, watch, defineEmits, onMounted, ref, nextTick} from 'vue'
import Jumbo from '@/components/Docs/Jumbo.vue'
import DocSection from '@/components/Docs/Section.vue'
import CodeBlock from '@/components/Docs/CodeBlock.vue'
import JsonBlock from '@/components/Docs/JsonBlock.vue'

import StringParam from '@/components/Docs/Parameters/StringParam.vue'
import RequestRunner from '@/components/Docs/RequestRunner.vue'

import BasicAttribute from '@/components/Docs/Attributes/BasicAttribute.vue'

let request_ip = ref("1.1.1.1")
let request_offset = ref("")
let request_limit = ref("")


let get_query = computed(() => {

  var query = "/query/ip/" + request_ip.value + "/reverse_dns"
  
  if (request_limit.value != "" || request_offset.value != "") query += "?"
  
  if (request_limit.value != "") {
    query += "limit="+request_limit.value
  }
  if (request_offset.value != "") {
    if (request_limit.value != "") query += "&"
    query += "offset="+request_offset.value
  }

  return query
}) 

</script>

<template>
  <div>
    <doc-section title="Reverse DNS">
      <template v-slot:left>
        <div class="mb-5">
Using the reverse DNS endpoint users are able to get a list of domains which currently resolve to a specific IP address. The endpoint returns a maximum of 1000 (200 for free users) domains per request, but can be paginated using the optional “offset” query parameter. 
        </div>
      
        <div class="mb-5">
          <h4 class="mb-4">Request parameters</h4>

          <StringParam class="mb-3" name="IP address" :required="true" description="The ip to find domains resolving to" type="string" :value="request_ip" @value="(v) => request_ip = v" />
          <StringParam class="mb-3" name="offset" description="The amount of domains you have already retrieved for that ip" type="integer" :value="request_offset" @value="(v) => request_offset = v" />
          <StringParam class="mb-3" name="limit" description="The amount of domains returned on each request. Defaults to 1000 which is also the maximum" type="integer" :value="request_limit" @value="(v) => request_limit = v" />
          <RequestRunner class="mt-5" :query="get_query" name="Domain information" method="GET"></RequestRunner>
        </div>

        <div>
          <h4 class="mb-4">Response attributes</h4>

          <BasicAttribute name="status" type="string" description="The status of the request's response" />
          <hr>

          <BasicAttribute name="data" type="object array" description="The requested information">

            <BasicAttribute name="domain" type="string" description="The requested domain" />
            <hr>

            <BasicAttribute name="ips" type="object array" description="The IPs which the domain resolves to, or have, if it currently doesn't resolve to any ip">
              <BasicAttribute name="ip" type="string" description="An ip the domain has recently resolved to" />
              <hr>
              <BasicAttribute name="risk_score" type="integer" description="Webscout's determination of the risk associated with the IP. Displayed in the form of a number between 0-100" />
              <hr>
              <BasicAttribute name="country" type="string" description="Two letter country code" />
              <hr>
              <BasicAttribute name="network_provider" type="string" description="The entity managing the ip address" />
            </BasicAttribute>
            <hr>

            <BasicAttribute name="num_emails" type="integer" description="The number of email addresses we have opserved on the domain" />
            <hr>

            <BasicAttribute name="num_subdomains" type="integer" description="The number of subdomains we have found on the domain" />
            <hr>

            <BasicAttribute name="registrant_org" type="string" description="The organization that registered the domain" />
            <hr>

            <BasicAttribute name="registrant_email" type="string" description="The email used to register the domain" />
            <hr>

            <BasicAttribute name="registration_date" type="string" description="The date the domain was registered" />
            <hr>

            <BasicAttribute name="expiration_date" type="string" description="The date the domain registration expires" />
            <hr>

            <BasicAttribute name="behavior" type="string array" description="Activity we have observed related to the domain or it's infrastructure" />

          </BasicAttribute>


        </div>

      </template>

      <template v-slot:right>
        <code-block title="/query/ip/:ip/reverse_dns" method="GET" dark/>
      
        <json-block title="Response">
{
"status": "success",
"data": [{
"domain": "mariadbsadmz04prod.brusselsairport.aero",
"ips": [
{
"ip": "1.1.1.1",
"risk_score": 0,
"country": "AU",
"network_provider": "Cloudflare DNS"
}
],
"num_emails": 0,
"num_subdomains": 0,
"registrant_org": null,
"registrant_email": null,
"registration_date": null,
"expiration_date": null,
"behavior": null
},
{
"domain": "comtechcloud.com.au",
"ips": [
{
"ip": "1.1.1.1",
"risk_score": 0,
"country": "AU",
"network_provider": "Cloudflare DNS"
}
],
"num_emails": 0,
"num_subdomains": 0,
"registrant_org": null,
"registrant_email": null,
"registration_date": null,
"expiration_date": null,
"behavior": null
}]
}
        </json-block>
      </template>
    </doc-section>
  </div>
</template>

<style scoped>
</style>
