<script setup>
import {onMounted, ref, defineProps, computed, nextTick, watch} from 'vue'
import { useMainStore } from '@/stores/main'
import Domain from '@/api/Domain'
import Ip from '@/api/Ip'
import User from '@/api/User'
import Streams from '@/api/Streams'
import QueryGuide from '@/views/Authenticated/Query/Guide.vue'
import CellExpandedSlideover from '@/components/Query/CellDataSlideover.vue'

let mainStore = useMainStore()

import QueryTable from '@/components/Query/Table.vue'
import IpView from '@/views/Authenticated/IpView.vue'
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

const display_type = ref("ip_table")
const failed = ref(false)
const results = ref(null)
const running = ref(false)
const error_message = ref("")

const props = defineProps({
  input: {
    type: Array,
    default: ["92.222.171.6", "62.33.115.241"]
  },
  type: {
    type: String,
    default: 'ip_table'
  },
  log: {
    type: Boolean,
    default: true
  }
});

const columns = {
  ip_table: [
    { data: "risk_score", title: "Risk", name: "risk", width: "55px" },
    { data: "location", title: "Location", name: "location", width: "90px" },
    { data: "ip", title: "Ip", name: "ip", width: "110px" },
    { data: "hostnames", title: "Hostnames", name: "hostnames" },
    { data: "domains_on_ip", title: "Domains", name: "domains_on_ip" },
    { data: "anonymization", title: "Anonymization", name: "anonymization" },
    { data: "network.type", title: "Network type", name: "network" },
    { data: "behavior.tags", title: "Behavior", name: "behavior" },
    { data: "osint.tags", title: "OSINT", name: "osint" },
    { data: "company.name", title: "Company", name: "companyname" },
    { data: "company.business", title: "Company Business", name: "companybusiness" },
  ],

  // there seems to be some alignment problems when adding the provider section
  // is this because this filters down the results (has dynamic sizing) 
  // there also seems to be issues when the same names is resused (this should be done, but can happen)
  // maybe a .adjust() should be called to be sure?

  // NOTE: The issue is when we cannot calculate the size of the cell (use of margins and paddings), as solution is to just box the content in fixed width containers
  // Calling adjust would also solve it, but when to do that is then an other issue
  subdomains: [
    { data: "risk_score", title: "Risk", name: "risk", width: "55px" },
    { data: "domain", title: "Domain", name: "domain", width: "250px" },
    { data: "ips", title: "Ips", name: "ips" },
    { data: "ips", title: "Network Provider", name: "provider" },
    { data: "behavior.tags", title: "Behavior", name: "behavior" },
    { data: "osint.tags", title: "OSINT", name: "osint" },
  ],
  domain_table: [
    { data: "risk_score", title: "Risk", name: "risk", width: "55px" },
    { data: "domain", title: "Domain", name: "domain", width: "250px" },
    { data: "ips", title: "Ips", name: "ips" },
    { data: "ips", title: "Network Provider", name: "provider" },
    { data: "num_emails", title: "Emails", name: "emails" },
    { data: "num_subdomains", title: "Subdomains", name: "subdomains" },
    { data: "registrant_org", title: "Registrant Organization", name: "registrant_org" },
    { data: "registration_date", title: "Registrantion date", name: "registration_date" },
    { data: "expiration_date", title: "Expiration date", name: "expiration_date" },
    { data: "behavior.tags", title: "Behavior", name: "behavior" },
  ],
  emails: [
    { data: "name", title: "Email", name: "email" },
  ],
  ip_fingerprints: [
    { data: "hash", title: "Fingerprint", name: "fingerprint" },
    { data: "hash_type", title: "Type", name: "fingerprint_type" },
    { data: "last_seen", title: "Observed", name: "observed" },
  ],
  ip_events: [
    { data: "timestamp", title: "Timestamp", name: "timestamp" },
    { data: "activity_type", title: "Activity", name: "type" },
    { data: "conn_type", title: "Connection type", name: "conn_type" },
    { data: "orig_h", title: "Originating IP", name: "orig_h" },
    { data: "orig_p", title: "Originating port", name: "orig_p" },
    { data: "resp_p", title: "Response port", name: "resp_p" },
    { data: "service", title: "Service", name: "service" },
    { data: "client", title: "Client", name: "client" },
    { data: "client_header_names", title: "Client Header", name: "client_header" },
    { data: "client_header_values", title: "Client Values", name: "client_values" },
    { data: "hassh", title: "Hassh", name: "hassh" },
    { data: "http_version", title: "HTTP version", name: "http_version" },
    { data: "ja3", title: "JA3", name: "ja3" },
    { data: "ja4", title: "JA4", name: "ja4" },
    { data: "ja4h", title: "JA4h", name: "ja4h" },
    { data: "ja4l", title: "JA4l", name: "ja4l" },
    { data: "ja4ssh", title: "JA4ssh", name: "ja4ssh" },
    { data: "ja4t", title: "JA4t", name: "ja4t" },
    { data: "method", title: "method", name: "method" },
    { data: "rdp_client_build", title: "RDP build", name: "rdp_client_build" },
    { data: "rdp_client_channels", title: "RDP channels", name: "rdp_client_channels" },
    { data: "rdp_client_name", title: "RDP name", name: "rdp_client_name" },
    { data: "rdp_cookie", title: "RDP cookie", name: "rdp_cookie" },
    { data: "rdp_desktop_height", title: "RDP Height", name: "rdp_height" },
    { data: "rdp_desktop_width", title: "RDP Width", name: "rdp_width" },
    { data: "rdp_keyboard_layout", title: "RDP Layout", name: "rdp_layout" },
    { data: "rdp_requested_color_depth", title: "RDP color depth", name: "rdp_requested_color_depth" },
    { data: "ssh_version", title: "SSH version", name: "ssh_version" },
    { data: "status_code", title: "Status Code", name: "status_code" },
    { data: "uri", title: "URI", name: "uri" },
    { data: "user_agent", title: "User Agent", name: "user_agent" },
  ],
}
// copy columns
columns.similar = columns.domain_table
columns.fingerprint_ips = columns.ip_table
columns.reverse_dns = columns.domain_table
columns.signals_toptalkers = columns.ip_table


/*
async function copy_result_to_clipboard() {
  let str = JSON.stringify(queryStore.result);
  await navigator.clipboard.writeText(str)
}
*/

let actions = {
  'num_emails': async (row) => {return [(await Domain.emails(row.domain)).data, "Emails for " + row.domain, "table"]},
  'num_ips': async (row) => {return [(await Domain.resolves(row.domain)).data, "Resolves for " + row.domain, "table"]},
  'num_subdomains': async (row) => {return [(await Domain.subdomains(row.domain)).data, "Subdomains for " + row.domain, "table"]},
  'ips': async (row) => {
      if (row.ips.length == 1) return [(await Ip.Get(row.ips[0])).data, "Ip for " + row.domain, "ip"]
      return [(await Ip.bulk(row.ips)).data, "Ips for " + row.domain, "table"]
    },
  'ip': async (row) => {return [(await Ip.Get(row.ip)).data, row.ip, "ip"]},
  'domains_on_ip': async (row) => {return [(await Domain.reverse_dns(row.ip)).data, "Domains for " + row.ip, "table"]},
  'osint_mentions': async (row) => {
    let value = row.domain
    if (row.domain == undefined) value = row.ip
    return [(await Streams.contains(value)).data, "OSINT Mentions for " + value]
  },
}

async function handle_cell_click(data) {
  let cellname = data.cell_name;
  if (actions.hasOwnProperty(cellname)) {
    let c = await actions[cellname](data.obj)
    let title = c[1]
    let d = c[0]
    mainStore.show_slideover(CellExpandedSlideover, { data: { 'title': title, 'data': d, type: c[2]}})
  } 
}

onMounted(async () => {
  running.value = true;
  
  try {
    if (props.type == "ip") {
      results.value = (await Ip.Get(props.input[0])).data
    }
    else if (props.type == "ip_table") {
      results.value = (await Ip.bulk(props.input)).data
    }
    else if (props.type == "subdomains") {
      results.value = (await Domain.subdomains(props.input[0])).data
    }
    else if (props.type == "emails") {
      results.value = (await Domain.emails(props.input[0])).data
    }
    else if (props.type == "similar") {
      results.value = (await Domain.similar(props.input[0])).data
    }
    else if (props.type == "reverse_dns") {
      results.value = (await Domain.reverse_dns(props.input[0])).data
    }
    else if (props.type == "fingerprint_ips") {
      results.value = (await Ip.Fingerprint2Ip(props.input[0])).data
    }
    else if (props.type == "ip_fingerprints") {
      results.value = (await Ip.Hashes(props.input[0])).data
    }
    else if (props.type == "signals_toptalkers") {
      results.value = (await Ip.EventTopTalkers(props.input[0], props.input[1])).data
    }
    else if (props.type == "ip_events") {
      results.value = (await Ip.EventRange(props.input[0], props.input[1], props.input[2]))
    } else {
      alert("NOT A VALID QUERY")
    }

    if (props.log) {
      User.save_query(props.input, props.type) 
    }

  } catch(e) {
    failed.value = true 
    error_message.value = e
  }
  running.value = false
})


</script>

<template>
  <div v-if="!failed" style="height: 100%; width: 100%; display: flex; padding: 10px; ">
    <div v-if="!running" style=" display: flex; min-height: 0; width: 100%; flex: 1; flex-direction: column;">
      <IpView v-if="props.type == 'ip' && results != null" :data="results"/>
      <QueryTable v-else class="wbg" :data="results" :columns="columns[props.type]" style="min-height: 0; flex: 1;" @rowclick="handle_cell_click" />
    </div>
    <div v-else class="d-flex centered vertically-centered column" style="justify-content: center; width: 100%;">
      <h2 class="mb-3 jura">Loading</h2>
      <div class="icon">
        <font-awesome-icon class="ft" style="font-size: 4rem; color: #7A8696" :icon="['fas', 'crosshairs']" beat-fade />
      </div>
    </div>
  </div>
  <div v-else>
    <div class="d-flex column centered pt-5" style="height: 100%;">
      <font-awesome-icon style="font-size: 7rem; color: rgb(202, 80, 80);" class="mb-4" :icon="['fas', 'circle-exclamation']"/>
      <div>
        <h4 class="text-center">Query failed</h4>
        <p class="text-center">
            {{ error_message }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

.jsonheader {
  display: flex;
  background-color: #151a22;
  border-top: 2px solid #191f27;
  color: white;
  height: 43px;
}

.jsonheader div {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-left: 1px solid #191f27;
}

.clickable:hover {
  background-color: #2B3440;
  cursor: pointer;
}

.clickable {
  font-weight: normal;
}
</style>

