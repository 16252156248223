<script setup>
import {defineProps, computed, watch, defineEmits, onMounted, ref, nextTick} from 'vue'
import Jumbo from '@/components/Docs/Jumbo.vue'
import DocSection from '@/components/Docs/Section.vue'
import CodeBlock from '@/components/Docs/CodeBlock.vue'
import JsonBlock from '@/components/Docs/JsonBlock.vue'


import StringParam from '@/components/Docs/Parameters/StringParam.vue'
import RequestRunner from '@/components/Docs/RequestRunner.vue'


import BasicAttribute from '@/components/Docs/Attributes/BasicAttribute.vue'

let request_ip = ref("89.36.76.126")

let get_query = computed(() => {
  return "/query/ip/" + request_ip.value + "" 
}) 

</script>

<template>
  <div>
    <doc-section title="Ip information">
      <template v-slot:left>
        <div class="mb-5">
        This endpoint returns a collection of general information on an IP address. <br><br>
        Note that the returned information may vary depending on the plan that you are currently on. For instance provider and operator information requires the Basic plan, and behavioral information require the Professional plan.
        </div>
      
        <div class="mb-5">
          <h4 class="mb-4">Request parameters</h4>

          <StringParam class="mb-3" name="IP address" :required="true" description="The ip address you wish to know about" type="string" :value="request_ip" @value="(v) => request_ip = v" />
          <RequestRunner class="mt-5" :query="get_query" name="Ip information" method="GET"></RequestRunner>
        </div>

        <div>
          <h4 class="mb-4">Response attributes</h4>


          <BasicAttribute name="status" type="string" description="The status of the request's response" />
          <hr>

          <BasicAttribute name="data" type="object" description="The requested information">

            <BasicAttribute name="risk_score" type="integer" description="Webscout's determination of the risk associated with the IP. Displayed in the form of a number between 0-100." />
            <hr>

            <BasicAttribute name="ip" type="string" description="The target IP address" />
            <hr>

            <BasicAttribute name="location" type="object" description="The location of the ip">
              <BasicAttribute name="country_iso" type="string" description="Two letter country code" />
              <hr>
              <BasicAttribute name="city" type="string" description="The full name of the city" />
            </BasicAttribute>

            <BasicAttribute name="anonymization" type="object" description="">
              <BasicAttribute name="vpn" type="boolean" description="whether or not the ip has been used as a vpn" />
              <hr>
              <BasicAttribute name="proxy" type="boolean" description="whether or not the ip has been used as a proxy" />
              <hr>
              <BasicAttribute name="tor" type="boolean" description="whether or not the ip is involved in the tor network" />
              <hr>
              <BasicAttribute name="service" type="string" description="The name of the operator of the anonymization service" />
            </BasicAttribute>

            <BasicAttribute name="network" type="object" description="">
              <BasicAttribute name="type" type="string" description="The likely type of infrastructure used to operate the ip" />
              <hr>
              <BasicAttribute name="service" type="string" description="The service the ip is a part of. For instance S3, cdn.." />
              <hr>
              <BasicAttribute name="region" type="string" description="This will usually map to a cloud providers regional mappings" />
              <hr>
              <BasicAttribute name="risk_score" type="integer" description="The risk_score assosiated with the network range" />
              <hr>
              <BasicAttribute name="range" type="string" description="The closest allocation of addresses assosiated with the ip address" />
              <hr>
              <BasicAttribute name="private" type="boolean" description="whether or not the ip belongs to a reversed network space" />
            </BasicAttribute>

            <BasicAttribute name="as" type="object" description="">
              <BasicAttribute name="as_numbers" type="integer array" description="The AS number(s) of the organization owning the ip address allocation" />
              <hr>
              <BasicAttribute name="organization" type="string" description="The name of the organization owning the ip address allocation" />
            </BasicAttribute>

            <BasicAttribute name="hostnames" type="string array" description="List of hostnames announced by the IP or operator" />
            <hr>

            <BasicAttribute name="domains_on_ip" type="integer" description="The number of domains resolving to the IP" />
            <hr>

            <BasicAttribute name="company" type="object" description="">
              <BasicAttribute name="name" type="string" description="The entity managing the ip address" />
              <hr>
              <BasicAttribute name="business" type="string array" description="A list of services the company provides as part of it's business" />
              <hr>
              <BasicAttribute name="description" type="string" description="A brief description of who the network provider is" />
            </BasicAttribute>

            <BasicAttribute name="behavior" type="object" description="">
              <BasicAttribute name="tags" type="string array" description="Activity assosiated with the IP address" />
              <hr>
              <BasicAttribute name="open_ports" type="integer array" description="List of open ports on the system, likely denoting its purpose" />
              <hr>
              <BasicAttribute name="last_seen" type="string" description="When we last observed behavior from this ip" />
            </BasicAttribute>

            <BasicAttribute name="osint" type="object" description="">
              <BasicAttribute name="tags" type="string array" description="Activity assosiated with the IP address as reported in public sources" />
              <hr>
              <BasicAttribute name="first_seen" type="string" description="When we first observed reporting on this ip" />
              <hr>
              <BasicAttribute name="last_seen" type="string" description="When we last observed reporting on this ip" />
            </BasicAttribute>
  
          </BasicAttribute>


        </div>

      </template>

      <template v-slot:right>
        <code-block title="/query/ip/:ip" method="GET" :dark="true"/>
      
        <json-block title="Response">
{
"status": 
"success",
"data": 
{
"risk_score": 
100,
"ip": 
"89.36.76.126",
"location": 
{
"country_iso": 
"DE",
"city": 
"Berlin"
},
"anonymization": 
{
"vpn": 
true,
"proxy": 
false,
"tor": 
false,
"service": 
"Surfshark VPN"
},
"network": 
{
"type": 
"Cloud",
"service": 
"",
"region": 
"",
"risk_score": 
0,
"range": 
"89.36.76.0/24",
"private": 
false
},
"as": 
{
"organization": 
"M247 Europe SRL",
"as_numbers": 
[
9009
]
},
"hostnames": 
null,
"domains_on_ip": 
1,
"company": 
{
"name": 
"M247",
"business": 
[
"Backup/Recovery",
"Business Internet",
"Cloud",
"Colocation",
"Hosting",
"VPC"
],
"description": 
"An internet service provider offering a range of services including broadband connectivity, data center solutions, and cloud services."
},
"behavior": 
{
"tags": 
[
"anonymization",
"malicious",
"vpn"
],
"open_ports": 
[
7443,
8443
],
"last_seen": 
"2025-03-20T13:19:46Z"
},
"osint": 
{
"tags": 
null,
"first_seen": 
null,
"last_seen": 
null
}
}
}
        </json-block>
      </template>
    </doc-section>
  </div>
</template>
