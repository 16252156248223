<script setup>
import {defineProps, watch, onMounted, ref} from 'vue'

const props = defineProps({
    "show": Boolean,
    //"properties": Object,
    "component": Object,
    "options": Object
})

const emit = defineEmits(['slideover-close']);

const default_options = {
  'width': 50,
}

const options = ref({...default_options})

onMounted(() => {
  if (props.options != undefined && props.options != null) {
    options.value = Object.assign(options.value, props.options)
  }
}) 

watch(() => props.options, (newval) => {
  if (newval == undefined || newval == null) {
    options.value = {...default_options}
    return
  }
  options.value = Object.assign(options.value, props.options)
})

</script>

<template>
  <div v-if="props.show" @click.self="emit('slideover-close')" @keydown.esc="emit('slideover-close')" id="overlay">
    <div class="close" :style="{ 'left': 'calc(' + options.width + '% - 55px)' }">
      <button type="button" @click="$emit('slideover-close')" class="btn-close btn-close-white" aria-label="Close"></button>
    </div>
    <div id="slideover" :style="{ 'left': options.width + '%' }">
      <div id="component">
        <component v-if="props.component.component|| props.component == {}" @slideover-close="$emit('slideover-close')" :is="props.component.component" v-bind="props.component.properties" :key="props.component.name"/>
        <slot v-else />
      </div>
    </div>
  </div>
</template>

<style scoped>
.close {
    position: absolute;
    left: 50px;
    top: 40px;
    background-color: #191f27;
    padding: 1rem;
    border-radius: 10px 0 0 10px;
    box-shadow: 1px 0 6px 6px #0c101561;
    border-left: 1px solid #1c2532;
}

.btn-close:active {
  box-shadow: none;
}

#overlay {
  background-color: #1318209e;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

#slideover {
  display: block;
  background-color: #191f27;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  /* left: 50%; */
  z-index: 100;
  border-radius: 10px 0 0 10px;
  box-shadow: 1px 0 6px 6px #0c101561;
  border-left: 1px solid #1c2532;
}

#component {
  height: 100%;
  width: 100%;
  position: relative;
  position: block;
}
</style>
