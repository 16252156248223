<template>
  <div>
    <Input title="IP fingerprints" :loading="loading" placeholder="1.1.1.1" description="See the advanced network fingerprints (JA3, JA4+) Webscout has observed for a given IP." @submit="handle"/>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Input from '@/components/Blocks/Box/Input.vue'
import { useQueryStore } from '@/stores/query'
import { useMainStore } from '@/stores/main'
import Ip from '@/api/Ip'
import CellExpandedSlideover from '@/components/Query/CellDataSlideover.vue'
let mainStore = useMainStore()
let queryStore = useQueryStore()
import QueryRunner from '@/views/Authenticated/Query/Runner.vue'

const loading = ref(false)

async function handle(fingerprint) {
  mainStore.show_slideover(QueryRunner, { input: [fingerprint], type: "ip_fingerprints" }, { width: 10 }) 
}
</script>

<style scoped>

</style>
