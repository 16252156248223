import { defineStore } from 'pinia'

import Query from "@/api/Query.js"
import Ip from "@/api/Ip.js"

/*
  Install as Store as Pinia.

  Create an api js file in a separate folder with useAxios inside and define a function that fetches to your backend server.

  Define an action inside your Pinia store that calls the api method and sets the data inside the store.

  Create a computed inside your Component that returns the store value.

  Call the Action inside created with async await inside your Component.
*/

export const useQueryStore = defineStore('query', {
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      query_string: "",
      
      error: "",

      result: null,

      running: false,

      display_json: false,

      view: 'table',
    }
  },

  getters: {
    failed: (state) => {
      return state.error != ""
    },

    empty: (state) => {
      return state.result == null
    },

    valid_query: (state) => {
      return state.query_string != ""
    }
  },

  actions: {
    reset() {
      this.result = null; 
      this.error = "";
    },

    async query(query) {
      let ip_regex = /^(?:ip\s*=\s*)?((?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})$/
      this.query_string = query.trim()
      if (this.query_string == '') {
        this.reset()
        return
      }

      this.running = true

      if (query.match(ip_regex)) {
        this.view = 'ip'
        let values = ip_regex.exec(this.query_string)
        let res = await Ip.Get(values[1])
        this.result = res;

        if (res.status == "success") {
          this.result = res.data
          this.error = "" 
        } else {
          this.error = res.status
        }
      } else {
        this.view = 'table'
        let res = await Query.custom(query)
        if (res.status == "success") {
          this.result = res.data
          this.error = "" 
        } else {
          this.error = res.status
        }
      }




      
      this.running = false
      return this.result
    }
  },

})
