<script setup>
import { onMounted, ref } from 'vue'

import Headline from '@/components/Blocks/Typo/Headline.vue'
import Graph from '@/components/Events/Graph.vue'
import Map from '@/components/Graphics/Map.vue'
import Ip from '@/api/Ip'
import Table from '@/components/Query/Table'
import QueryRunner from '@/views/Authenticated/Query/Runner.vue'

const props = defineProps({
  start: { 
    type: Number,
    default: Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-7))
  },
  end: { 
    type: Number,
    default: Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())),
  },
});

const data = ref([])

onMounted( async () => {
  /*
  let ips = await Ip.EventTopTalkers(Math.floor(props.start / 1000), Math.floor(props.end / 1000))
  data.value = ips.data
  */
})

</script>


<template>
  <div class="container pt-5" style="overflow: auto;">
    <Headline title="Signals" divider class="mb-3" /> 

    <div class="row">
      <div class="col-xxl-6 mb-4">
        <div class="box dark p-4" style="height: 100%;">
          <Graph />
        </div>
      </div>
      <div class="col-xxl-6 mb-4">
        <div class="box dark p-4">
          <Map />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12" style="max-height: 500px;">
        <div class="box mb-5 d-flex column">
          <h5 class="white d-flex pt-3 ps-3 jura mb-1">
            Toptalkers
          </h5>

          <div style="height: 500px; width: 100%;">
            <QueryRunner :input="[Math.floor(props.start / 1000), Math.floor(props.end / 1000)]" type="signals_toptalkers" :log="false"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>
