<template>
  <div class="chart-container">
     <canvas id="myChart"></canvas>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'
import Chart from 'chart.js/auto'
import Ip from '@/api/Ip'

const now = new Date();
var chart;

const props = defineProps({
  start: { 
    type: Number,
    default: Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-7))
  },
  end: { 
    type: Number,
    default: Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())),
  },
});


onMounted(async () => {
  let jsonData = (await Ip.EventStats(Math.floor(props.start / 1000), Math.floor(props.end / 1000))) 

 const labels = jsonData.data.map(entry => new Date(entry.day).toISOString().slice(0, 10));
  const categories = ['connection', 'http', 'rdp', 'ssh', 'ssl'];
  const colors = {
    connection: 'rgba(75, 192, 192, 0.6)',
    http: 'rgba(255, 205, 86, 0.6)',
    rdp: 'rgba(255, 99, 132, 0.6)',
    ssh: 'rgba(54, 162, 235, 0.6)',
    ssl: 'rgba(153, 102, 255, 0.6)'
  };

  const datasets = categories.map(category => ({
    label: category.toUpperCase(),
    data: jsonData.data.map(entry => entry.event_distribution[category] || 0),
    backgroundColor: colors[category],
    borderColor: colors[category].replace('0.6', '1'),
    fill: true,
    tension: 0.4
  }));

  const ctx = document.getElementById('myChart');
  chart = new Chart(ctx, {
    type: 'line',
    data: {
      labels,
      datasets
    },
    options: {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false
      },
      stacked: true,
      plugins: {
        title: {
          display: false,
          text: 'Event types'
        },
        tooltip: {
          mode: 'index',
          intersect: false
        }
      },
      scales: {
        x: {
          title: {
            display: false,
            text: 'Date'
          }
        },
        y: {
          stacked: true,
          title: {
            display: false,
            text: 'Number of Events'
          }
        }
      }
    }
  });
})


onUnmounted( () => {
  if ( chart != undefined ) chart.destroy();
})


</script>

<style scoped>

.chart-container {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}

</style>
