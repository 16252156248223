<template>
  <div>
    <Input title="Reverse DNS" placeholder="1.1.1.1" description="See which domains Webscout has observed pointing to a given IP address." :loading="loading" @submit="handle" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Input from '@/components/Blocks/Box/Input.vue'
import { useQueryStore } from '@/stores/query'
import { useMainStore } from '@/stores/main'
import Domain from '@/api/Domain'
import CellExpandedSlideover from '@/components/Query/CellDataSlideover.vue'
let mainStore = useMainStore()
let queryStore = useQueryStore()
import QueryRunner from '@/views/Authenticated/Query/Runner.vue'

const loading = ref(false)

async function handle(ip) {
  mainStore.show_slideover(QueryRunner, { input: [ip], type: "reverse_dns" }, { width: 10 }) 
}
</script>

<style scoped>

</style>
