<script setup>
import {defineProps, computed, watch, defineEmits, onMounted, ref, nextTick} from 'vue'
import Jumbo from '@/components/Docs/Jumbo.vue'
import DocSection from '@/components/Docs/Section.vue'
import CodeBlock from '@/components/Docs/CodeBlock.vue'
import JsonBlock from '@/components/Docs/JsonBlock.vue'

import StringParam from '@/components/Docs/Parameters/StringParam.vue'
import RequestRunner from '@/components/Docs/RequestRunner.vue'

import BasicAttribute from '@/components/Docs/Attributes/BasicAttribute.vue'

let request_hash = ref("21109_2_1460_0")
let request_type = ref(202)

let get_query = computed(() => {
  return "/query/hash/" + request_hash.value 
}) 

</script>

<template>
  <div>
    <doc-section title="Hash">
      <template v-slot:left>
        <div class="mb-5">
          <div class="mb-3">
            The hash endpoint allows for retrieving ip addresses associated with a given hash or fingerprint. The output is tightly coupled with the type of hash you look up. A table of the supported hash types, and the their output types can be seen below.</div>

        </div>
      
        <div class="mb-5">
          <h4 class="mb-4">Request parameters</h4>
          <StringParam class="mb-3" name="Hash" :required="true" description="The hash you wish to lookup" type="string" :value="request_hash" @value="(v) => request_hash = v" />

          <RequestRunner class="mt-5" :query="get_query" name="Domain information" method="GET"></RequestRunner>
        </div>

        <div>
          <h4 class="mb-4">Response attributes</h4>

          <BasicAttribute name="status" type="string" description="The status of the request's response" />
          <hr>

          <BasicAttribute name="data" type="object" description="The requested information">

            <BasicAttribute name="risk_score" type="integer" description="Webscout's determination of the risk associated with the IP. Displayed in the form of a number between 0-100." />
            <hr>

            <BasicAttribute name="ip" type="string" description="The target IP address" />
            <hr>

            <BasicAttribute name="location" type="object" description="The location of the ip">
              <BasicAttribute name="country_iso" type="string" description="Two letter country code" />
              <hr>
              <BasicAttribute name="city" type="string" description="The full name of the city" />
            </BasicAttribute>

            <BasicAttribute name="anonymization" type="object" description="">
              <BasicAttribute name="vpn" type="boolean" description="whether or not the ip has been used as a vpn" />
              <hr>
              <BasicAttribute name="proxy" type="boolean" description="whether or not the ip has been used as a proxy" />
              <hr>
              <BasicAttribute name="tor" type="boolean" description="whether or not the ip is involved in the tor network" />
              <hr>
              <BasicAttribute name="service" type="string" description="The name of the operator of the anonymization service" />
            </BasicAttribute>

            <BasicAttribute name="network" type="object" description="">
              <BasicAttribute name="type" type="string" description="The likely type of infrastructure used to operate the ip" />
              <hr>
              <BasicAttribute name="service" type="string" description="The service the ip is a part of. For instance S3, cdn.." />
              <hr>
              <BasicAttribute name="region" type="string" description="This will usually map to a cloud providers regional mappings" />
              <hr>
              <BasicAttribute name="risk_score" type="integer" description="The risk_score assosiated with the network range" />
              <hr>
              <BasicAttribute name="range" type="string" description="The closest allocation of addresses assosiated with the ip address" />
              <hr>
              <BasicAttribute name="private" type="boolean" description="whether or not the ip belongs to a reversed network space" />
            </BasicAttribute>

            <BasicAttribute name="as" type="object" description="">
              <BasicAttribute name="as_numbers" type="integer array" description="The AS number(s) of the organization owning the ip address allocation" />
              <hr>
              <BasicAttribute name="organization" type="string" description="The name of the organization owning the ip address allocation" />
            </BasicAttribute>

            <BasicAttribute name="hostnames" type="string array" description="List of hostnames announced by the IP or operator" />
            <hr>

            <BasicAttribute name="domains_on_ip" type="integer" description="The number of domains resolving to the IP" />
            <hr>

            <BasicAttribute name="company" type="object" description="">
              <BasicAttribute name="name" type="string" description="The entity managing the ip address" />
              <hr>
              <BasicAttribute name="business" type="string array" description="A list of services the company provides as part of it's business" />
              <hr>
              <BasicAttribute name="description" type="string" description="A brief description of who the network provider is" />
            </BasicAttribute>

            <BasicAttribute name="behavior" type="object" description="">
              <BasicAttribute name="tags" type="string array" description="Activity assosiated with the IP address" />
              <hr>
              <BasicAttribute name="open_ports" type="integer array" description="List of open ports on the system, likely denoting its purpose" />
              <hr>
              <BasicAttribute name="last_seen" type="string" description="When we last observed behavior from this ip" />
            </BasicAttribute>

            <BasicAttribute name="osint" type="object" description="">
              <BasicAttribute name="tags" type="string array" description="Activity assosiated with the IP address as reported in public sources" />
              <hr>
              <BasicAttribute name="first_seen" type="string" description="When we first observed reporting on this ip" />
              <hr>
              <BasicAttribute name="last_seen" type="string" description="When we last observed reporting on this ip" />
            </BasicAttribute>
  
          </BasicAttribute>

        </div>

      </template>

      <template v-slot:right>
        <code-block title="/query/hash/:hash/:type" method="GET" dark/>
      
        <json-block title="Response">
{
"status": "success",
"data": [
{
"ip": "207.90.244.14",
"location": {
"country_iso": "US"
},
"domains_on_ip": 0,
"operator": "",
"network_type": "Cloud",
"network_provider": "SHODAN",
"network_provider_services": [
"Cloud",
"Security"
],
"behavior": [
"bruteforce",
"bruteforce_login",
"bruteforce_telnet",
"crime",
"malicious",
"probing",
"probing_http",
"probing_https",
"probing_kafka",
"probing_ldap",
"probing_mysql",
"probing_rdp",
"probing_rpc",
"probing_smb",
"probing_telnet",
"probing_vnc",
"scanning",
"scanning_dns_tcp",
"scanning_http",
"scanning_https",
"scanning_smtp",
"scanning_ssh",
"scanning_vnc"
],
"open_ports": null,
"as_org": "SHODAN, LLC",
"asn": [
174
],
"avg_ttl": 0,
"provider_description": "A search engine for Internet-connected devices, showcasing various web services and security aspects.",
"operator_description": "This IP address has been observed in unauthorized connections or connection attempts against Webscout's sensor net",
"os_guess": "",
"risk_score": 100
}
]
}
        </json-block>
      </template>
    </doc-section>
  </div>
</template>

<style scoped>
  
.table {
  width: 100%;
  table-layout: fixed;
}


.table th {
  color: white;
  font-weight: normal
}

.table tr {
  border-bottom: 1px solid #29323E;
}

.table tbody tr:last-child {
  border-bottom: 1px solid transparent;
}

.table td {
  color: #6D7785;
}

.table .first {
  width: 3.5rem;
}

.table .second {
  width: 10rem;
}
</style>
