<script setup>
import {onMounted, ref, defineProps, computed, nextTick, watch} from 'vue'
const emit = defineEmits(["submit"]);

const props = defineProps({
  title: {
    type: String,
    default: ""
  }, 

  loading: {
    type: Boolean,
    default: false
  }, 

  placeholder: {
    type: String,
    default: "",
  },

  autocomplete: {
    type: String,
    default: "off",
  },

  description: {
    type: String,
    default: "",
  }
});

const input = ref(null)

function submit() {
  emit("submit", input.value)
}
  
</script>

<template>
  <div class="box">
    <div v-if="props.title != ''" class="p-3 header ps-4" >
      {{ props.title }}
    </div>

    <form class="p-3" @submit.prevent="submit">
      <div class="input">
          <input type="text" v-model="input" class="" :autocomplete="props.autocomplete" id="floatingInput" :placeholder="props.placeholder" >
          <div class="icon" @click="submit" v-if="!props.loading">
            <font-awesome-icon class="ft" style="font-size: 1rem; color: #7A8696" :icon="['fas', 'search']" />
          </div>
          <div class="icon" v-else>
            <font-awesome-icon class="ft" style="font-size: 1rem; color: #7A8696" :icon="['fas', 'spinner']" spin />
          </div>
      </div>

      <div v-if="props.description" class="mt-3 ps-2">
        {{ props.description }}
      </div>

    </form>
  </div>
</template>

<style scoped>

.header {
  background-color: #292f39;
  color: white; 
}

.input {
  background-color: #171C24;
  border:none;
  outline:none;
  color:white;
  width: 100%;
  border-radius: 10px;
  padding: .8rem 1rem;
  position: relative;
}

input {
  background-color: transparent;
  border:none;
  outline:none;
  color:white;
  width: 100%;
}

.icon {
  position: absolute;
  right: 10px; 
  top: 10px;
  border: 1px solid #7a869666;
  padding: .2rem .5rem;
  border-radius: 5px;
}

.icon:hover {
  border: 1px solid white;
  cursor: pointer;
}



  
</style>
