<script setup>
import {onMounted, ref, defineProps, defineEmits, computed, nextTick, watch} from 'vue'

const emit = defineEmits([ "file" ]);

const props = defineProps({
  clear: {
    type: Boolean,
    default: true
  }
});

function c() {
  const fileInput = document.getElementById('fileinput');

  if (props.clear) fileInput.value = null;

  fileInput.click(); // open the file dialog

  fileInput.addEventListener('change', (event) => {
    const file = event.target.files[0];
    emit("file", file)
    //fileInput.value = null;
    /*
    if (!file) return;
    const reader = new FileReader();
    reader.onload = function(e) {
      const fileContents = e.target.result;
      // You can also process the string here
      // processString(fileContents);
      console.log(fileContents)
    };
    reader.readAsText(file); // read the file as text
    */
  }); 
}

</script>


<template>
  <div class="b" @click="c">
    
    <slot/>
    <input type="file" id="fileinput" style="display:none;"/>
  </div>
</template>

<style scoped>

.b {
  background-color: #1E252F;
  border-radius: 10px;
  padding: .6rem 1.6rem;
  display: inline-block;
  transition: all .1s ease; 
}

.b:hover {
  cursor: pointer;
  color: white;
  background-color: #222a35;
}

.b.blue {
  background-color: #4587e7;
  color: white;
}

.b.blue:hover {
  background-color: #5B94E6
}

</style>
