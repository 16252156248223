<script setup>
import {onMounted, ref, defineProps, computed, nextTick, watch} from 'vue'
import Button from '@/components/Blocks/Buttons/Default.vue'
import FileInput from '@/components/Blocks/Buttons/File.vue'
import FileAnalyser from '@/lib/file_analyzer/analyzer.js'
import QueryRunner from '@/views/Authenticated/Query/Runner.vue'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

const query = ref(null)
const file_hover = ref(false)

function submit() {
  if (query.value == null) return
  let value = query.value.trim().split("\n")
  if (value.length == 1) {
   mainStore.show_slideover(QueryRunner, { input: value, type: "ip" }, { width: 10 }) 
  } else if (value.length > 1) {
   mainStore.show_slideover(QueryRunner, { input: value, type: "ip_table" }, { width: 10 }) 
  }
}

function process_text(text) {
  let res = FileAnalyser.analyze(text)
  query.value = res.ips.join("\n")
}

function process_file(file) {
  const reader = new FileReader();

  reader.addEventListener('load', (event) => {
    const result = event.target.result;
    // Do something with result
    process_text(result)
    // delete file
  });
  
  /*
  reader.addEventListener('progress', (event) => {
    if (event.loaded && event.total) {
      const percent = (event.loaded / event.total)
      analyzing_percentage.value = percent
    }
  });
  */
  reader.readAsText(file);
}

function onDrop(e) {
  if (e.dataTransfer.files.length) {
    let file = e.dataTransfer.files[0]
    process_file(file)
  } else {
    process_text(e.dataTransfer.getData("Text"))
  }
  file_hover.value = false
}

function paste(e) {
  if(e.clipboardData.files.length) {
    let file = e.clipboardData.files[0];
    process_file(file)
  } else {
    process_text((event.clipboardData || window.clipboardData).getData("text"))
  }
}

async function read_clipboard() {
  let value = await navigator.clipboard.readText();
  alert(value)
}

</script>

<template>
  <div class="box dark p-4 bordered mb-5 d-flex column" :class="{file_hover: file_hover}" @drop.prevent="onDrop" @dragenter.prevent="file_hover = true" @dragover.prevent="file_hover = true" @dragleave.prevent="file_hover = false">
    <div style="flex: 1;">
      <textarea v-model="query" @paste.prevent="paste" placeholder="Paste from clipboard, upload, drag and drop, or select a file with IP addresses to analyze.\nSupports structured and unstructured input.
"></textarea>
    </div>
    <div class="toolbar d-flex">
      <FileInput class="me-2" @file="process_file">load from file</FileInput>
      <!--<Button class="me-2" @click="read_clipboard">paste clipboard</Button>-->
      <Button class="ms-auto" @click="submit" blue>submit</Button>
    </div>
</div>
</template>

<style scoped>

textarea {
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: white;
  padding: 1rem;
  outline: none; 
  resize: none;
  border: none;
}

.file_hover {
  border: 1px solid #95f395 !important;
}

</style>
