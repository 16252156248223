<template>
  <div>
    <Input title="Fingerprint search" :loading="loading" placeholder="21109_2_1460_0" description="Find other IP addresses Webscout has identified as sharing a given fingerprint." @submit="handle"/>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Input from '@/components/Blocks/Box/Input.vue'
import { useQueryStore } from '@/stores/query'
import { useMainStore } from '@/stores/main'
import Ip from '@/api/Ip'
import CellExpandedSlideover from '@/components/Query/CellDataSlideover.vue'
let mainStore = useMainStore()
let queryStore = useQueryStore()
import QueryRunner from '@/views/Authenticated/Query/Runner.vue'

const loading = ref(false)

async function handle(fingerprint) {
  mainStore.show_slideover(QueryRunner, { input: [fingerprint], type: "fingerprint_ips" }, { width: 10 }) 
}
</script>

<style scoped>

</style>
