<script setup>
import {defineProps} from 'vue'

const props = defineProps(["data"])

const data = []

if (props.data != undefined) {
  for(let i = 0; i<props.data.length; i++) {
    if (props.data[i].country_iso != "") {
      data.push(props.data[i])
    }
  }
}
</script>

<template>
  <div v-if="props.data != undefined && data.length > 0" class="d-flex vertically-centered">
    <img height="13" v-if="data[0].country_iso != ''" class="me-2" style="border-radius: 5px;" :src="require('@/assets/flags/4x3/' + data[0].country_iso.toLowerCase() + '.svg')">
    <span>{{ data[0].country_iso }}</span>
    <span v-if="data.length > 1" class="b ms-2"> +{{ data.length - 1}}</span>
  </div>
  <div v-else-if="props.data != undefined && props.data != null && !Array.isArray(props.data)" class="d-flex vertically-centered">
    <img height="13" v-if="props.data.country_iso != ''" class="me-2" style="border-radius: 5px;" :src="require('@/assets/flags/4x3/' + props.data.country_iso.toLowerCase() + '.svg')">
    <span v-if="props.data.country_name != ''">{{ props.data.country_iso }}</span>
    <span v-else>{{ props.data.country_iso }}</span>
  </div>
</template>

<style scoped>
span {
  font-size: .9rem;
}
.b{
  background-color: #37414f;
  border-radius: 24px;
  padding: 0 7px;
  font-size: 0.8rem;
  font-weight: bold;
}

</style>

