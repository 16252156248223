<script setup>
import {onMounted, ref, defineProps, computed, nextTick, watch} from 'vue'
import moment from 'moment'
import Plans from '@/data/plans.js'

import { useUserStore } from '@/stores/user'
let userStore = useUserStore()

import { useQueryStore } from '@/stores/query'
let queryStore = useQueryStore()

import { useRouter } from 'vue-router'
const router = useRouter()

let plan = computed( () => {
  return Plans.filter( p => p.account_type == userStore.accountType )[0]
}) 

onMounted(  async () => {
  await userStore.details()
})

</script>


<template>
      <div class="box padded">
        <div class="row mb-4">
          <div class="col-md-3">
            <div class="title usage">Requests today</div>
            <div><strong  style="font-size: 1.4rem;" class="white">{{ userStore.api_daily_spend }}</strong>&nbsp; / {{ userStore.limit_api_daily}}</div>
          </div>

          <div class="col-md-3">
            <div class="title usage">Requests this month</div>
            <div><strong  style="font-size: 1.4rem;" class="white">{{ userStore.api_monthly_spend }}</strong>&nbsp; / {{ userStore.limit_api_monthly}}</div>
          </div>

          <div class="col-md-3 ms-auto d-flex">
            <router-link to="/plans" class="button dark ms-auto" style="align-self: flex-end; padding: .5rem 2rem; text-transform: uppercase;" :style="{color: plan.color}">{{plan.title}}</router-link>
          </div>
        </div>
        <div>
          <div class="progress" :style="{'background-color':  plan.color_transparent}" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar progress-bar-striped progress-bar-animated" :style="{width:  ((userStore.api_monthly_spend/userStore.limit_api_monthly)*100) + '%', 'background-color': plan.color}"></div>
          </div>
        </div>
    </div>

</template>

<style scoped>

.button {
  background-color: #1E252F;
  border-radius: 5px;
  border:none;
  margin-right: 5px;
  padding: 0 10px;
  font-weight: bold;
  color: white;
  padding: .5rem 1rem;
}

.button.dark {
  background-color: #131820
}

.button:hover {
  background-color: #2B3440;
}
</style>
