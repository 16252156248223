<script setup>
import {defineProps} from 'vue'

const props = defineProps(["data"])


let data = [];
if (props.data != null) {
  data = props.data.slice(0,4)
}

</script>

<template>
  <div v-if="props.data != null && data.length > 0" class="d-flex vertically-centered">

    <div v-for="ip in data"  class="d-flex vertically-centered" style="width: 135px;">
      <img height="13" v-if="ip.country != ''" style="border-radius: 5px; display: block;" :src="require('@/assets/flags/4x3/' + ip.country.toLowerCase() + '.svg')">
      <div class="ps-1">{{ props.data[0].ip }}</div>
    </div>
    <!--<span v-if="props.data.length > 1" class="b ms-2"> +{{ props.data.length - 1}}</span>-->
  </div>
</template>

<style scoped>
.b{
  background-color: #37414f;
  border-radius: 24px;
  padding: 0 7px;
  font-size: 0.8rem;
  font-weight: bold;
}

</style>

