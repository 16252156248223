<script setup>
import { onMounted, onUnmounted, defineProps, watch } from 'vue'
import * as d3 from 'd3'
import * as topojson from "topojson-client";
import world_map from '@/assets/map/countries-110m.json'

import Ip from '@/api/Ip.js'
//import A2C from '@/assets/map/alpha2country.json'
import Countries from '@/assets/map/all.json'

var id2name = {}

const props = defineProps({
    data: {
      type: Object,
      default: {},
      required: true
    },
    start: { 
      type: Number,
      default: Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-7))
    },
    end: { 
      type: Number,
      default: Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())),
    }
})

var colorScale = d3.scaleThreshold()
  .domain([1, 100, 1000, 5000, 10000])
  .range([
    "#191F27",
    "#4f93ff26", // +1
    "#4f93ff42", // +100
    "#4f93ff61", // +1000
    "#4f93ff61", // +1000
    "#4f93ffa6", // +5000
    //"#4f93ff"
    "#4f93ff" //+10000
  ]);

// eslint-disable-next-line no-unused-vars
function sort(systems) {
    const sortable = Object.entries(systems)
    sortable.sort(([,a],[,b]) => b['risk_score'] - a['risk_score'])
    return sortable
}

let svg = null;

function create_map(data) {
  d3.select('#map').selectAll('*').remove()

    var width = 960,
        height = 500;

  // create a tooltip
  var Tooltip = d3.select("#map")
    .append("div")
    .style("opacity", 0)
    .attr("class", "d3tooltip")
    
  var projection = d3.geoMercator()
      .center([0, 5 ]);
      // .scale(150)
      // .rotate([-180,0]);
  
  svg = d3.select("#map").append("svg")
      .attr("id", "svg")
      .attr("viewBox", "0 0 " + width + " " + height )
      .attr("preserveAspectRatio", "xMinYMin")
      .attr('style', 'border-radius: 20px;');

  var mousemove = function(event, d) {
    Tooltip
      .html(id2name[d.id] + "<br>"+d.total +" bad ips")
      //.style("left", (event.pageX+70) + "px")
      //.style("top", (event.pageY) + "px")
  }

  let mouseOver = function(d) {
      Tooltip
        .style("opacity", 1)
      d3.selectAll(".country")
        .transition()
        .duration(200)
        .style("opacity", .3)
      d3.select(this)
        .transition()
        .duration(200)
        .style("opacity", 1)
        .style("stroke", "white")
        .style("cursor", "pointer")
    }

  let mouseLeave = function(d) {
    Tooltip
      .style("opacity", 0)
    d3.selectAll(".country")
      .transition()
      .duration(200)
      .style("opacity", 1)
      .style("stroke", "#526276")
    d3.select(this)
      .transition()
      .duration(200)
      .style("stroke", "#526276")
  }
    
  var path = d3.geoPath()
      .projection(projection);
  
  var g = svg.append("g");
  g.selectAll("path")
      .data(topojson.feature(world_map, world_map.objects.countries).features)
      .enter()
      .append("path")
        .attr("d", path)
        .attr("class", "d3country")
        .attr("fill", function(d) {
            d.total = data[d.id] || 0;
            return colorScale(d.total);
        })
        .on("mouseover", mouseOver )
        .on("mouseleave", mouseLeave )
        .on("mousemove", mousemove)
  
}

function redraw() {
  svg.remove()
  create_map(props.data)
}

/*
watch( () => props.data, () => {
  redraw()
})
*/

onMounted(async () => {
  let data = (await Ip.EventStatsCountry(Math.floor(props.start / 1000), Math.floor(props.end / 1000)) ).data 

  var country_list = {}
  for (let i=0; i<Countries.length;i++) {
    country_list[Countries[i]["alpha-2"].toUpperCase()] = Countries[i]["country-code"]
    id2name[Countries[i]["country-code"]] = Countries[i].name
  }

  var country_mapping = {}
  for (const [key, value] of Object.entries(data)) {
    country_mapping[country_list[key]] = value
  }

  create_map(country_mapping)
});

onUnmounted(() => {
  svg.remove()
})
</script>

<template>
  <div class="map-wrapper">
    <div id="map"></div>
    <div class="no-data" v-if="props.data.length == 0">No location data</div>
  </div>
</template>

<style >
.d3country {
    /* stroke: #161b22; */
    stroke: #526276;
    /* fill: #0c0f13; */
    /*fill: #191F27;*/
    stroke-width: .25;
}

/*
.label {
    position: absolute;
    left: 0;
    top: 0;
    background: #191f27;
    padding: 0 .5rem;
    border-radius: 0 0 15px 0;
    line-height: 18px;
    padding-bottom: 0.6rem;
}

#scrollspy .nav-link {
    display: flex;
    align-items: center;
}

.map-wrapper {
  position: relative;
}

.no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #171d24;
  color: white;
  padding: .6rem 1rem;
}
*/

.d3tooltip {
  position: absolute;
  height: 100px;
  color: white;
  right: 0; 
  bottom: 0;
}
</style>
