<script setup>
import {defineProps, computed, watch, defineEmits, onMounted, ref, nextTick} from 'vue'
import Jumbo from '@/components/Docs/Jumbo.vue'
import DocSection from '@/components/Docs/Section.vue'
import CodeBlock from '@/components/Docs/CodeBlock.vue'
import JsonBlock from '@/components/Docs/JsonBlock.vue'

import StringParam from '@/components/Docs/Parameters/StringParam.vue'
import RequestRunner from '@/components/Docs/RequestRunner.vue'

import BasicAttribute from '@/components/Docs/Attributes/BasicAttribute.vue'

let request_domain = ref("reddit.com")
let request_offset = ref("")
let request_limit = ref("")


let get_query = computed(() => {

  var query = "/query/domain/" + request_domain.value + "/emails"
  
  if (request_limit.value != "" || request_offset.value != "") query += "?"
  
  if (request_limit.value != "") {
    query += "limit="+request_limit.value
  }
  if (request_offset.value != "") {
    if (request_limit.value != "") query += "&"
    query += "offset="+request_offset.value
  }

  return query
}) 

</script>

<template>
  <div>
    <doc-section title="Emails">
      <template v-slot:left>
        <div class="mb-5">
This endpoint allows users to retrieve a list of emails associated with a given domain. The returned list is limited to a maximum of 200 entries per request, but it’s possible to cursor through the results using the optional "offset" query parameter. 
        </div>
      
        <div class="mb-5">
          <h4 class="mb-4">Request parameters</h4>

          <StringParam class="mb-3" name="Domain" :required="true" description="The domain you wish to find subdomains of" type="string" :value="request_domain" @value="(v) => request_domain = v" />
          <StringParam class="mb-3" name="offset" description="The amount of emails you have already retrieved for that domain" type="integer" :value="request_offset" @value="(v) => request_offset = v" />
          <StringParam class="mb-3" name="limit" description="The amount of emails returned on each request. Defaults to 1000 which is also the maximum" type="integer" :value="request_limit" @value="(v) => request_limit = v" />

          <RequestRunner class="mt-5" :query="get_query" name="Domain information" method="GET"></RequestRunner>
        </div>

        <div>
          <h4 class="mb-4">Response attributes</h4>

          <BasicAttribute name="status" type="string" description="The status of the request's response" />
          <hr>

          <BasicAttribute name="data" type="object">

            <BasicAttribute name="name" type="string" description="An email address" />

          </BasicAttribute>


        </div>

      </template>

      <template v-slot:right>
        <code-block title="/query/domain/:domain/emails" method="GET" dark/>
      
        <json-block title="Response">
{
"status": "success",
"data": [{ "name": "test@example.com"}, { "name": "dev@example.com"}]
}
        </json-block>
      </template>
    </doc-section>
  </div>
</template>

<style scoped>
</style>
